<template>
    <div class="container-header">
        <div class="container-row-header container-row-header-no-border">
            <img @click="openHamburger" class="img-logo-mini" src="../../assets/shared/logo-mini.svg" alt="">
        </div>
        <div class="container-row-header">
            <span v-if="$route.path === '/' && !storeIntents.addIntentGetters" class="text-current-link">Percorsi</span>
            <div class="container-back" v-else-if="storeIntents.addIntentGetters">
                <img @click="storeIntents.addIntent = false" src="../../assets/header/back.svg" alt="">
                <span class="text-current-link">Nuovo percorso</span>
            </div>
            <div class="container-back" v-else-if="storeEntity.addEntityGetters">
                <img @click="storeEntity.addEntity = false" src="../../assets/header/back.svg" alt="">
                <span class="text-current-link">Nuova Entità</span>
            </div>
            <div class="container-back" v-else-if="storeForm.addFormGetters">
                <img @click="storeForm.addForm = false" src="../../assets/header/back.svg" alt="">
                <span class="text-current-link">Nuovo Form</span>
            </div>
            <div class="container-back" v-else-if="storeSettings.addSettingGetters">
                <img @click="storeSettings.addSetting = false" src="../../assets/header/back.svg" alt="">
                <span class="text-current-link">Nuova Impostazione</span>
            </div>
            <div class="container-back" v-else-if="storeDocument.addDocumentGetters">
                <img @click="storeDocument.addDocument = false" src="../../assets/header/back.svg" alt="">
                <span class="text-current-link">Nuovo Documento</span>
            </div>
            <div class="container-back" v-else-if="$route.path === '/intent'">
                <img @click="$router.back()" src="../../assets/header/back.svg" alt="">
                <span v-if="storeIntents.intentGetters"
                      class="text-current-link">Dettaglio {{ storeIntents.intentGetters.displayName }}</span>
            </div>
            <div class="container-back" v-else-if="$route.path === '/detailEntity'">
                <img @click="$router.back()" src="../../assets/header/back.svg" alt="">
                <span v-if="storeEntity.entityTypeGetters"
                      class="text-current-link">Dettaglio {{ storeEntity.entityTypeGetters.displayName }}</span>
            </div>
            <div class="container-back" v-else-if="$route.name === 'detailSetting'">
                <img @click="$router.back()" src="../../assets/header/back.svg" alt="">
                <span v-if="storeSettings.settingGetters"
                      class="text-current-link">Dettaglio {{ storeSettings.settingGetters.name }}</span>
            </div>
            <div class="container-back" v-else-if="$route.name === 'detailForm'">
                <img @click="$router.back()" src="../../assets/header/back.svg" alt="">
                <span v-if="storeForm.formGetters"
                      class="text-current-link">Dettaglio {{ storeForm.formGetters.name }}</span>
            </div>
            <div class="container-back" v-else-if="$route.name === 'detailFormRecord'">
                <img @click="$router.back()" src="../../assets/header/back.svg" alt="">
                <span v-if="storeFormRecord.formRecordGetters"
                      class="text-current-link">Dettaglio {{ storeFormRecord.formRecordGetters.form.name }}</span>
            </div>
            <div class="container-back" v-else-if="$route.name === 'detailDocument'">
                <img @click="$router.back()" src="../../assets/header/back.svg" alt="">
                <span v-if="storeDocument.documentGetters"
                      class="text-current-link">Dettaglio {{ storeDocument.documentGetters.name }}</span>
            </div>
            <span v-else-if="$route.path === '/documents'" class="text-current-link">Documenti</span>
            <span v-else-if="$route.path === '/entities'" class="text-current-link">Entità</span>
            <span v-else-if="$route.path === '/forms'" class="text-current-link">Form</span>
            <span v-else-if="$route.path === '/form-records'" class="text-current-link">Form record</span>
            <span v-else-if="$route.path === '/settings'" class="text-current-link">Impostazioni</span>
            <span v-else-if="$route.path === '/feedbacks'" class="text-current-link">Feedback</span>
            <div id="clickbox1" @click="openedMenu" class="container-menu-profile">
                <div class="container-content-menu-profile-left">
                    <span v-if="storeUser.userGetters" class="text-username">{{ storeUser.userGetters.username }}</span>
                    <span v-else-if="storeAuth.userGetters" class="text-username">{{
                        storeAuth.userGetters.username
                        }}</span>
                    <span v-if="storeUser.userGetters" class="text-email">{{ storeUser.userGetters.email }}</span>
                    <span v-else-if="storeAuth.userGetters" class="text-email">{{ storeAuth.userGetters.email }}</span>
                </div>
                <div class="container-content-menu-profile-right">
                    <img src="../../assets/shared/arrow_bottom.svg" alt="">
                </div>
            </div>
            <div id="clickbox" v-show="showDropdownMenu" class="container-dropdown-menu-header-bar">
                <!--        <div @click="goToPath('profile')"-->
                <!--             class="container-single-dropdown-menu-header-bar">-->
                <!--          <img class="img-menu" src="../../assets/header/user.svg" alt="">-->
                <!--          <span class="title-small">Profilo</span>-->
                <!--        </div>-->
                <div @click="logout" class="container-single-dropdown-menu-header-bar">
                    <img class="img-menu" src="../../assets/header/logout.svg" alt="">
                    <span class="title-small">Logout</span>
                </div>
            </div>
        </div>

        <div class="container-row-header">
            <div class="container-path">
                <span class="text-path-grey">Avatar /</span>
                <span :class="{'text-path-grey': storeIntents.addIntentGetters}" v-if="$route.path === '/'"
                      class="text-path">Percorsi</span>
                <span v-else-if="$route.path === '/documents'" class="text-path">Documenti</span>
                <span v-else-if="$route.path === '/forms'" class="text-path">Form</span>
                <span v-else-if="$route.path === '/form-records'" class="text-path">Form record</span>
                <span v-else-if="$route.path === '/settings'" class="text-path">Impostazioni</span>
                <span v-else-if="$route.path === '/calls'" class="text-path">Calls</span>
                <span v-else-if="$route.path === '/calendar'" class="text-path">Calendar</span>
                <span v-else-if="$route.name === 'detailFormRecord'" class="text-path">Form record</span>
                <span v-else-if="$route.name === 'detailDocument'" class="text-path">Documento</span>
                <span v-else-if="$route.name === 'detailSetting'" class="text-path">Impostazione</span>
                <span v-else-if="$route.name === 'feedbacks'" class="text-path">Feedback</span>
                <span :class="{'text-path-grey': storeEntity.addEntityGetters}" v-else-if="$route.path === '/entities'"
                      class="text-path">Entità</span>
                <span class="text-path" v-if="storeIntents.addIntentGetters">/ Nuovo percorso</span>
                <span class="text-path" v-else-if="storeEntity.addEntityGetters">/ Nuova Entità</span>
                <span class="text-path" v-else-if="storeForm.addFormGetters">/ Nuovo Form</span>
                <span class="text-path" v-else-if="storeDocument.addDocumentGetters">/ Nuovo Documento</span>
                <span class="text-path" v-else-if="storeSettings.addSettingGetters">/ Nuova Impostazione</span>
                <span class="text-path" v-else-if="$route.path === '/intent'">Dettaglio</span>
                <span class="text-path" v-else-if="$route.path === '/detailEntity'">Dettaglio</span>
                <span class="text-path" v-else-if="$route.name === 'detailForm'">Dettaglio</span>
                <span class="text-path" v-else-if="$route.name === 'detailDocument'">Dettaglio</span>
                <span class="text-path" v-else-if="$route.name === 'detailSetting'">Dettaglio</span>
                <span></span>
            </div>
        </div>
        <div :class="{'container_filter': $route.path === '/'}" class="container-filter"
             v-if="!storeForm.addFormGetters && !storeDocument.addDocumentGetters && !storeSettings.addSettingGetters && !storeIntents.addIntentGetters && !storeEntity.addEntityGetters && $route.path !== '/intent' && $route.name !== 'detailDocument' && $route.name !== 'detailFormRecord' && $route.name !== 'detailForm' && $route.name !== 'settings' && $route.name !== 'detailSetting' && $route.path !== '/detailEntity'">
            <b-form-input
                    v-if="!storeIntents.addIntentGetters && !storeSettings.addSettingGetters && !storeEntity.addEntityGetters && $route.path !== '/settings' && $route.path !== '/calls' && $route.path !== '/'"
                    v-model="search"
                    class="search-bar" placeholder="Cerca"
                    type="text"></b-form-input>
            <button @click="addedEntity" v-if="$route.path === '/entities'"
                    class="btn btn-primary-custom btn-primary-custom-colors-dark">Crea Entità
            </button>
            <button @click="addedForm" v-if="$route.path === '/forms'"
                    class="btn btn-primary-custom btn-primary-custom-colors-dark">Crea form
            </button>
            <button @click="addedDocument" v-if="$route.path === '/documents'"
                    class="btn btn-primary-custom btn-primary-custom-colors-dark">Carica documento
            </button>
            <!--      button for create setting -->
            <!--      <button @click="addedSettings" v-if="$route.path === '/settings'"-->
            <!--              class="btn btn-primary-custom btn-primary-custom-colors-dark">Crea impostazione-->
            <!--      </button>-->
        </div>
    </div>
</template>

<script>
import {useUserStore} from "@/stores/users";
import {useIntentStore} from "@/stores/intents";
import {useEntityStore} from "@/stores/entity";
import {useAuthStore} from "@/stores/auth";
import {useFormStore} from "@/stores/forms";
import {useApiSettingStore} from "@/stores/apiSettings";
import {useDocumentStore} from "@/stores/documents";
import {useFormRecordStore} from "@/stores/formRecords";
import {useSettings} from "@/stores/settings";
import {useFeedbackStore} from "@/stores/feedback";

export default {
    name: "ComponentHeader",
    components: {},
    setup() {
        const storeAuth = useAuthStore()
        const storeUser = useUserStore()
        const storeEntity = useEntityStore()
        const storeIntents = useIntentStore()
        const storeForm = useFormStore()
        const storeFormRecord = useFormRecordStore()
        const storeDocument = useDocumentStore()
        const storeSettings = useSettings()
        return {
            storeSettings,
            storeDocument,
            storeAuth,
            storeFormRecord,
            storeEntity,
            storeForm,
            storeUser,
            storeIntents
        }
    },
    data() {
        return {
            search: '',
            showDropdownMenu: false
        }
    },
    created() {
        this.search = this.searchList
    },
    computed: {
        searchList() {
            return useUserStore().searchListGetters
        }
    },
    mounted() {
        window.addEventListener('click', (e) => {
            if (document.getElementById('clickbox1')) {
                if (!document.getElementById('clickbox1').contains(e.target) && document) {
                    this.closeMenu()
                }
            }

        });
    },
    methods: {
        logout() {
            useAuthStore().$reset()

            this.$router.push({name: 'login'}).catch(() => {
            })
        },
        addedIntent() {
            useIntentStore().addIntent = true
        },
        addedForm() {
            useFormStore().addForm = true
        },
        addedDocument() {
            useDocumentStore().addDocument = true
        },
        addedSettings() {
            useSettings().addSetting = true
        },
        addedEntity() {
            useEntityStore().addEntity = true
        },
        goToPath(path) {
            console.log(path)
        },
        closeMenu() {
            this.showDropdownMenu = false
        },
        openedMenu() {
            // funzione per calcolare la posizione esatta per aprire il menu rispetto al nome dell'utente
            let container_menu_profile = document.getElementsByClassName('container-menu-profile')[0]
            console.log(container_menu_profile.getBoundingClientRect())
            let container_dropdown_menu_header_bar = document.getElementsByClassName('container-dropdown-menu-header-bar')[0]
            container_dropdown_menu_header_bar.style.top = container_menu_profile.getBoundingClientRect().top + container_menu_profile.getBoundingClientRect().height + 10 + 'px'
            container_dropdown_menu_header_bar.style.right = '20px'
            container_dropdown_menu_header_bar.style.width = container_menu_profile.getBoundingClientRect().width + 'px'
            this.showDropdownMenu = !this.showDropdownMenu
        },
        openHamburger() {
            useApiSettingStore().overlayModal = true
            useApiSettingStore().hamburgerStatus = true
        }
    },
    watch: {
        search: {
            handler: function (search_input) {
                console.log(search_input)
                let queryParams = {}
                queryParams.search = search_input
                queryParams.page = useApiSettingStore().currentPageFilterGetters
                useApiSettingStore().searchFilter = search_input
                if (this.$route.path === '/forms') {

                    // queryParams.page = useFormStore().
                    useFormStore().getForms({
                        queryParams: queryParams
                    })
                        .then(() => {
                        })
                        .catch(() => {
                        })
                        .finally(() => {
                        })
                } else if (this.$route.path === '/documents') {
                    useDocumentStore().getDocuments({
                        queryParams: queryParams
                    })
                        .then(() => {
                        })
                        .catch(() => {
                        })
                        .finally(() => {
                        })
                } else if (this.$route.path === '/form-records') {
                    useFormRecordStore().getFormRecords({
                        queryParams: queryParams
                    })
                        .then(() => {
                        })
                        .catch(() => {
                        })
                        .finally(() => {
                        })
                } else if (this.$route.path === '/feedbacks') {
                    useFeedbackStore().getFeedbacks({
                        queryParams: queryParams
                    })
                        .then(() => {
                        })
                        .catch(() => {
                        })
                        .finally(() => {
                        })
                } else if (this.$route.path === '/entities') {
                    this.emitter.emit('filterSearchEntity', search_input)
                } else if (this.$route.path === '/') {
                    this.emitter.emit('filterSearch', search_input)
                } else if (this.$route.path === '/settings') {
                    useSettings().getSettings({
                        queryParams: queryParams
                    })
                        .then(() => {
                        })
                        .catch(() => {
                        })
                        .finally(() => {
                        })
                }
            }
        }
    },
    beforeUnmount() {
        window.removeEventListener('click', () => {
        })
    },
    unmounted() {
        window.removeEventListener('click', () => {
        })
    }
}
</script>

<style scoped>
.container-back img {
    cursor: pointer;
}

.container-back {
    display: flex;
    align-items: center;
}

.img-menu {
    margin-right: 10px;
}

.container-single-dropdown-menu-header-bar:hover {
    background: #D8D8D8;
}

.container-single-dropdown-menu-header-bar {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 10px 20px;
}

.container-dropdown-menu-header-bar {
    position: absolute;
    min-width: 200px !important;
    right: 0;
    background: var(--color-white);
    border: 1px solid var(--color-light-grey);
    border-radius: 8px;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.img-logo-mini {
    cursor: pointer;
}

.container-row-header-no-border.container-row-header {
    border-bottom: unset;
    display: none;
    padding: 20px 20px 0px 20px;
}

.btn-primary-custom {
    margin-left: auto;
}

.container-filter {
    display: flex;
    padding: 30px 20px;
}

.text-path {
    font-style: normal;
    font-weight: var(--font-weight-regular);
    font-size: var(--small);
    color: var(--color-black);
    margin-left: 5px;
}

.text-path-grey {
    font-style: normal;
    font-weight: var(--font-weight-regular);
    font-size: var(--small);
    color: var(--color-grey);
}

.container-row-header {
    display: flex;
    border-bottom: 1px solid var(--color-light-grey);
    padding: 20px;
    width: 100%;
    align-items: center;
}

.container_filter {
    padding: 0px 20px 30px 20px;
}

.container-menu-profile {
    cursor: pointer;
    display: flex;
    padding: 10px;
    align-items: center;
    border: 1px solid var(--color-light-grey);
    border-radius: 8px;
    margin-left: auto;
}

.text-username {
    font-style: normal;
    font-weight: var(--font-weight-regular);
    font-size: var(--small);
    color: var(--color-black);
}

.text-email {
    font-style: normal;
    font-weight: var(--font-weight-regular);
    font-size: var(--x-small);
    color: var(--color-grey);
}

.container-content-menu-profile-left {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.text-current-link {
    font-style: normal;
    font-weight: var(--font-weight-bold);
    font-size: var(--medium);
    color: var(--color-black);
}

.container-header {
    display: flex;
    flex-direction: column;
}


/*// X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {

}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
    .container-row-header-no-border.container-row-header {
        display: block;
    }
}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {

}
</style>