import {createRouter, createWebHistory} from 'vue-router'
// eslint-disable-next-line no-unused-vars
import {useAuthStore} from "@/stores/auth";
// import {useApiSettingStore} from "@/stores/apiSettings";

const routes = [
    {
        path: '/',
        name: 'dashboard',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "IntentView" */ '../views/IntentView')
    },
    {
        path: '/intent',
        name: 'detailIntent',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "DetailIntent" */ '../views/DetailIntent')
    },
    {
        path: '/form-records',
        name: 'formRecords',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "FormRecord" */ '../views/FormRecord')
    },
    {
        path: '/form-records/:id',
        name: 'detailFormRecord',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "DetailFormRecord" */ '../views/DetailFormRecord')
    },
    {
        path: '/settings',
        name: 'settings',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "SettingsView" */ '../views/SettingsView')
    },
    {
        path: '/feedbacks',
        name: 'feedbacks',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "FeedbackView" */ '../views/FeedbackView.vue')
    },
    {
        path: '/settings/:id',
        name: 'detailSetting',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "DetailSetting" */ '../views/DetailSetting')
    },
    {
        path: '/entities',
        name: 'entities',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "EntitiesView" */ '../views/EntitiesView')
    },
    {
        path: '/detailEntity',
        name: 'detailEntity',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "DetailEntity" */ '../views/DetailEntity')
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            hideForAuth: true
        },
        component: () => import(/* webpackChunkName: "LoginView" */ '../views/LoginView')
    },
    {
        path: '/register',
        name: 'register',
        meta: {
            hideForAuth: true
        },
        component: () => import(/* webpackChunkName: "RegisterView" */ '../views/RegisterView')
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        meta: {
            hideForAuth: true
        },
        component: () => import(/* webpackChunkName: "ResetPassword" */ '../views/ResetPassword')
    },
    {
        path: '/calls',
        name: 'calls',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "CallsView" */ '../views/CallsView')
    },
    {
        path: '/calendar',
        name: 'calendar',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "CalendarView" */ '../views/CalendarView.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: "/",
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (useAuthStore().isAuthenticated) {
            next();
            return;
        }
        next("/login");
    } else {
        next();
    }
});


router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.hideForAuth)) {
        if (useAuthStore().isAuthenticated) {
            next("/");
            return;
        }
        next();
    } else {
        next();
    }
});

export default router
